.link {
  display: flex;
  cursor: pointer;
}

.imgContainer {
  height: 52px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.appStore {
  width: 180px;
  background-image: url(../../assets/images/AppStore.png);
}

.ruStore {
  width: 156px;
  background-image: url(../../assets/images/RuStore.png);
}

.googlePlay {
  width: 180px;
  background-image: url(../../assets/images/GooglePlay.png);
}

@media screen and (max-width: 767px) {
  .imgContainer {
    height: 40px;
  }

  .appStore {
    width: 140px;
  }

  .ruStore {
    width: 121px;
  }

  .googlePlay {
    width: 139px;
  }
}

@media screen and (max-width: 427px) {
  .imgContainer {
    height: 35px;
  }

  .appStore {
    width: 122px;
  }

  .ruStore {
    width: 106px;
  }

  .googlePlay {
    width: 122px;
  }
}
