.consent {
  display: flex;
  justify-content: center;
  position: fixed;
  inset: 0;
  background-color: white;
  width: 100%;
  height: 100%;
  padding: 40px 0;
  overflow-y: auto;

  .content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 80%;
    color: #666666;
    padding-bottom: 40px;
    height: 100%;
  }

  .title {
    font-size: 24px;
    margin-bottom: 20px;
    line-height: 1.6em;
  }

  .text {
    font-size: 18px;
    font-weight: 400px;
    line-height: 1.6em;
    &:last-child {
      padding-bottom: 32px;
    }
  }
  .bold {
    font-weight: 600;
  }
}
