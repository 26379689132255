.content {
  width: 100%;

  .descriptionGame {
    display: flex;
    flex-direction: column;
    row-gap: 21px;
    margin-top: 82px;
  }

  .heading {
    font-size: 45px;
    font-weight: 500;
    line-height: 54.86px;
  }

  .description {
    font-size: 20px;
    font-weight: 400;
    line-height: 29.13px;
  }

  .nav {
    display: none;
  }
}

@media screen and (max-width: 1279px) {
  .content {
    width: clamp(359px, 100%, 736px);
    margin: 0 auto;
    .descriptionGame {
      margin-top: 69px;
    }

    .nav {
      display: block;
      position: fixed;
      inset: 0;
      width: 100%;
      height: 100%;
      background-color: var(--main-light);
      z-index: 3;
      transform: translateX(-100%);
      transition: all 0.2s ease;

      .container {
        overflow: auto;
        max-height: 100vh;
        padding: 27px 8px;
      }

      .container::-webkit-scrollbar {
        width: 3px;
        background-color: var(--main-light);
      }

      .container::-webkit-scrollbar-thumb {
        background-color: var(--light-text);
      }

      .navWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 43px;
        padding-left: 23px;
        padding-right: 16px;
      }

      &.isOpen {
        transform: translateX(0);
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .content {
    .descriptionGame {
      row-gap: 15px;
      margin-top: 64px;
    }

    .heading {
      font-size: 28px;
      line-height: 34.13px;
    }

    .description {
      font-size: 18px;
      line-height: 26.21px;
    }
  }
}

@media screen and (max-width: 427px) {
  .content {
    .descriptionGame {
      margin-top: 57px;
    }
  }
}
