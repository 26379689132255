.navLink {
  display: grid;
  grid-template-columns: 26px 1fr;
  column-gap: 18px;
  width: 100%;
  background: transparent;
  outline: none;
  border: none;
  border-radius: 14px;
  padding: 10px 22px 12px;
  cursor: pointer;

  &.active {
    background-color: var(--accent-light-color);
  }

  .playIcon {
    align-self: center;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 3px;
    font-family: 'Acrom';
  }

  .level {
    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
    text-transform: uppercase;
    color: var(--light-text);
    &.active {
      color: var(--accent-color-secondary);
    }
  }

  .nameLevel {
    font-size: 16px;
    font-weight: 500;
    line-height: 19.5px;
    color: var(--main-text);
    &.active {
      color: var(--accent-color-secondary);
    }
  }
}
