.footer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  max-width: 1256px;
  height: 46px;
  margin: 54px auto 0;

  .wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    text-align: right;
  }

  .copyright {
    font-size: 14px;
    font-weight: 400;
    line-height: 20.39px;
    color: var(--light-text);
  }

  .box {
    display: flex;
    gap: 22px;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.39px;
    color: var(--main-text);
  }

  .link {
    font-size: 14px;
    font-weight: 400;
    line-height: 20.39px;
    color: var(--main-text);
  }
}

@media screen and (max-width: 1279px) {
  .footer {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 11px;
    max-width: 100%;
    height: auto;
    width: clamp(359px, 100%, 736px);

    .wrapper {
      row-gap: 31px;
      text-align: start;
    }

    .box {
      gap: 16px;
      justify-content: flex-start;
    }
  }
}

@media screen and (max-width: 767px) {
  .footer {
    margin-top: 47px;
    .wrapper {
      row-gap: 11px;
    }
  }
}

@media screen and (max-width: 427px) {
  .footer {
    margin-top: 57px;
  }
}
