.modal {
  background-color: rgba(255, 255, 255, 1);
  padding: 16px 23px 16px 33px;
  max-width: 95%;

  @media (max-width: 620px) {
    padding: 16px 15px 31px 17px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
}

.text {
  max-width: 512px;
  text-align: center;
  font-family: "Acrom";
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  color: rgba(242, 102, 89, 1);
  margin-top: 19px;
  margin-left: 15px;

  @media (max-width: 620px) {
    margin-left: 4px;
  }
}

.warning_button {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
}

.list {
  display: flex;
  gap: 16px;
  margin-top: 19px;

  @media (max-width: 620px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
