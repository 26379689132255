@font-face {
    font-family: 'Acrom';
    src: url('Acrom-Bold.eot');
    src:
        local('Acrom Bold'),
        local('Acrom-Bold'),
        url('Acrom-Bold.eot?#iefix') format('embedded-opentype'),
        url('Acrom-Bold.woff2') format('woff2'),
        url('Acrom-Bold.woff') format('woff'),
        url('Acrom-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Acrom';
    src: url('Acrom-Regular.eot');
    src:
        local('Acrom'),
        local('Acrom-Regular'),
        url('Acrom-Regular.eot?#iefix') format('embedded-opentype'),
        url('Acrom-Regular.woff2') format('woff2'),
        url('Acrom-Regular.woff') format('woff'),
        url('Acrom-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Acrom';
    src: url('Acrom-Medium.eot');
    src:
        local('Acrom Medium'),
        local('Acrom-Medium'),
        url('Acrom-Medium.eot?#iefix') format('embedded-opentype'),
        url('Acrom-Medium.woff2') format('woff2'),
        url('Acrom-Medium.woff') format('woff'),
        url('Acrom-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
