.page {
  display: grid;
  grid-template-columns: 1fr 302px 42px 912px 1fr;
  grid-template-rows: 250px 1fr auto;
  row-gap: 30px;
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding-bottom: 22px;
  &.noScroll {
    height: 100vh;
    overflow-y: hidden;
  }

  .header {
    grid-column: 1 / span 5;
    width: 100%;
  }

  .leftColumn {
    grid-column: 2 / span 1;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .rightColumn {
    grid-column: 4 / span 1;
  }

  .footer {
    grid-column: 2 / span 3;
  }
}

@media screen and (max-width: 1279px) {
  .page {
    grid-template-columns: 16px 1fr 16px;
    grid-template-rows: 250px 1fr auto;
    row-gap: 16px;
    padding-bottom: 26px;

    .header {
      grid-column: 1 / span 3;
    }

    .leftColumn {
      width: clamp(359px, 100%, 736px);
      margin: 0 auto;
    }

    .nav-container {
      display: none;
    }

    .rightColumn {
      grid-column: 2 / span 1;
    }

    .footer {
      grid-column: 2 / span 1;
    }
  }
}

@media screen and (max-width: 767px) {
  .page {
    grid-template-columns: 8px 1fr 8px;
    grid-template-rows: 314px 1fr auto;
    row-gap: 12px;
    padding-bottom: 32px;
  }
}
