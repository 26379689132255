@import url(./shared/assets/font/stylesheet.css);

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
hr {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Acrom';
  margin: 0;
  padding: 0;
}

:root {
  --main-light: #ffffff;
  --main-text: #090b0d;
  --light-text: #8fa7bf;
  --accent-color: #ffa526;
  --accent-color-secondary: #e73030;
  --accent-color-bright: #ff9326;
  --accent-light-color: #fff4e5;
}
