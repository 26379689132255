.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--main-text);

  .topWrapper {
    display: flex;
    align-items: center;
    column-gap: 15px;
    color: var(--accent-color);
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    pointer-events: none;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 3px;
  }

  .elem {
    display: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.5px;
    color: var(--main-text);
  }

  .playIcon {
    stroke: var(--accent-color-bright);
    transition: all 0.2s linear;
  }

  .title {
    font-size: 45px;
    font-weight: 500;
    line-height: 54.86px;
    margin-top: 5px;
    margin-bottom: 23px;
  }

  .game {
    width: 100%;
  }

  .descriptionLevel {
    display: flex;
    flex-direction: column;
  }

  .subtitle {
    font-size: 20px;
    font-weight: 600;
    line-height: 24.38px;
    margin-bottom: 14px;
  }

  .description,
  .specification {
    font-size: 20px;
    line-height: 29.13px;
    font-weight: 400;
  }

  .description {
    font-style: italic;
  }

  .container {
    display: flex;
    flex-direction: column;
    row-gap: 38px;
    margin-top: 38px;
  }
}

@media screen and (max-width: 1279px) {
  .card {
    .topWrapper {
      justify-content: space-between;
      height: 62px;
      background-color: var(--accent-light-color);
      border-radius: 14px;
      padding: 11px 24px;
      pointer-events: all;
      cursor: pointer;
    }

    .elem {
      display: inline-block;
    }

    .playIcon {
      transform: rotate(90deg);
    }

    .title {
      font-size: 45px;
      font-weight: 500;
      line-height: 54.86px;
      margin-top: 32px;
      margin-bottom: 23px;
    }

    .container {
      row-gap: 30px;
      margin-top: 30px;
    }
  }
}

@media screen and (max-width: 767px) {
  .card {
    .title {
      font-size: 28px;
      line-height: 34.13px;
      margin-bottom: 11px;
    }

    .subtitle {
      margin-bottom: 11px;
    }

    .container {
      row-gap: 24px;
      margin-top: 24px;
    }

    .description,
    .specification {
      font-size: 18px;
      line-height: 26.21px;
    }

    .elem {
      color: var(--accent-color-secondary);
    }

    .level {
      text-transform: uppercase;
      color: var(--accent-color-secondary);
    }

    .playIcon {
      stroke: var(--accent-color-secondary);
      transform: rotate(90deg);
    }
  }
}