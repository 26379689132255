.header {
  width: 100%;
  background: url(../../shared/assets/images/desktop-background2.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 44px;
  margin: 0 auto;

  .headerContent {
    width: 100%;
    max-width: 1256px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1279px) {
  .header {
    background-repeat: no-repeat;
    background-position: top 0 left 35%;
    background-size: cover;
    padding-top: 26px;

    .headerContent {
      max-width: 736px;
    }
  }
}

@media screen and (max-width: 767px) {
  .header {
    background: url(../../shared/assets/images/tablet_background2.png);
    background-repeat: no-repeat;
    background-position: top 0 left 50%;
    background-size: cover;
    padding-top: 16px;

    .headerContent {
      display: flex;
      justify-content: center;
      max-width: 100%;
    }
  }
}
@media screen and (max-width: 480px) {
  .header {
    background: url(../../shared/assets/images/mobile-background2.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}
