.popupVideo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  background: rgba(46, 46, 46, 0.5);
  transition: 0.2s ease;
  visibility: hidden;
  opacity: 0;
  &.isOpen {
    visibility: visible;
    opacity: 1;
  }

  .content {
    display: flex;
    flex-direction: column;
    // width: 620px;
    // height: 420px;
    border-radius: 16px 16px 16px 16px;
    position: relative;
    // background-color: rgba(156, 156, 156, 1);
    position: relative;
  }

  .text {
    display: inline-block;
    position: absolute;
    width: 100%;
    top: 45%;
    color: var(--main-light);
    font-size: 26px;
    text-align: center;
    z-index: 1;
  }

  .playerContainer {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 16px;
    position: relative;
  }

  .iframe {
    width: 750px;
    height: 500px;
    border-radius: 16px;
    position: relative;
    z-index: 100;

    @media (max-width: 767px) {
      width: 100%;
      height: 250px;
    }
  }

  .closeButton {
    position: absolute;
    top: 0;
    right: -40px;
  }
}

@media screen and (max-width: 767px) {
  .popupVideo {
    padding-left: 8px;
    padding-right: 8px;

    .closeButton {
      top: -36px;
      right: 0;
    }

    .content {
      width: 100%;
      max-width: 400px;
      height: 259px;
    }
  }
}

.video {
  width: 100%;
  border-radius: 16px;
}
