.logo {
  .img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.placeHeader {
    width: 231px;
    height: 73px;
    background-color: var(--main-light);
    border-radius: 14px;
    padding: 14px 21px;
  }

  &.placeFooter {
    width: 160px;
    height: 38px;
  }

  &.placeMobileNav {
    width: 160px;
    height: 38px;
  }
}

@media screen and (max-width: 767px) {
  .logo {
    &.placeHeader {
      width: 195px;
      height: 61px;
      padding: 12px 17px;
    }
  }
}
