.mobileGame {
  display: none;
}

@media screen and (max-width: 1279px) {
  .mobileGame {
    display: block;
    width: 100%;
    margin-bottom: 36px;

    .container {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      width: 100%;
    }
    .iframe {
      font-size: 0;
      width: 100%;
      height: 100%;
      max-width: 912px;
      aspect-ratio: 8 / 5;
      border: none;
      border-radius: 14px;
    }

    .list {
      display: flex;
      column-gap: 16px;
      align-items: center;
    }
  }
}

@media screen and (max-width: 767px) {
  .mobileGame {
    margin-bottom: 32px;
    .container {
      row-gap: 12px;
    }

    .list {
      max-width: 428px;
      justify-content: space-between;
      column-gap: 0;
    }
  }
}
