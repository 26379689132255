.container {
  background-color: rgba(242, 102, 89, 0.09);
  border-radius: 14px;
  padding: 16px 128px 20px 46px;
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: 37px 1fr;
  justify-content: space-between;
  align-items: center;
  gap: 38px;

  @media (max-width: 620px) {
    padding: 10px 25px 11px 13px;
  }
}

.text {
  max-width: 663px;
  font-family: "Acrom";
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  color: rgba(242, 102, 89, 1);

  @media (max-width: 620px) {
    font-size: 12px;
    max-width: 276px;
    line-height: 17px;
  }
}
