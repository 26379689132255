.videoPlay {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 16px;
  width: 100%;
  height: 62px;
  border: none;
  border-radius: 14px;
  background-color: var(--accent-color-bright);
  padding: 20px;
  cursor: pointer;
  &:hover {
    background-color: var(--accent-color);
  }
  .btnText {
    font-family: Acrom;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.2px;
    color: var(--main-light);
  }
}
