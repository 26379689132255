.popup {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  background: rgba(46, 46, 46, 0.5);
  transition: 0.2s ease;
  visibility: hidden;
  opacity: 0;
  &.isOpen {
    visibility: visible;
    opacity: 1;
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 620px;
    overflow: hidden;
    border-radius: 16px 16px 16px 16px;
    position: relative;
    background-color: var(--main-light);
  }

  .imgContainer {
    width: 100%;
    height: 100%;
    max-height: 165px;
  }

  .image {
    width: 100%;
    height: 165px;
    object-fit: cover;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 40px;
    overflow: hidden;
    min-height: 255px;
    height: 100%;
    border: 1px solid #e6e6e6;
    border-radius: 0 0 16px 16px;
    padding: 41px 39px 36px;
  }

  .text {
    font-size: 20px;
    font-weight: 400;
    line-height: 29.13px;
    text-align: center;
    max-width: 473px;
    margin: 0 auto;
  }

  .form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px 16px;
  }

  .input {
    font-size: 20px;
    font-weight: 400;
    line-height: 29.13px;
    width: 263px;
    height: 50px;
    outline: none;
    border: none;
    border-radius: 8px;
    background-color: #f2f2f2;
    padding: 10px 23px 11px;
    &::placeholder {
      color: #a9a9a9;
      font-family: Acrom;
      font-size: 20px;
      font-weight: 400;
      line-height: 29.13px;
    }
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    outline: none;
    border: none;
    border-radius: 8px;
    background-color: #fab045;
    width: 263px;
    height: 50px;
    padding: 10px 45px;
    cursor: pointer;
    &[disabled] {
      opacity: 0.5;
      cursor: default;
    }
  }

  .btnText {
    font-family: Acrom;
    font-size: 20px;
    font-weight: 500;
    line-height: 29.13px;
    color: var(--main-light);
  }

  .playIcon {
    stroke: var(--main-light);
  }

  .closeButton {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .error {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -20px;
    font-size: 12px;
    color: var(--accent-color-secondary);
  }

  .label {
    position: relative;
  }

  .labelCheckbox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 16px;
    position: relative;
    grid-column: 1 / span 2;
    max-width: max-content;
    cursor: pointer;
  }

  .inputCheckbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
    width: 0;
    height: 0;
    &:checked + span {
      background-color: #fab045;
      border: 1px solid #fab045;
    }
  }

  .checkbox {
    display: inline-block;
    min-height: 21px;
    min-width: 21px;
    width: 21px;
    height: 21px;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #d2d2d2;
    transition: all 0.3s ease-in-out;
    &.erroCheckboxs {
      border: 1px solid var(--accent-color-secondary);
    }
  }

  .checkboxText {
    font-size: 13px;
    font-weight: 400;
    line-height: 19.88px;
    text-align: left;
  }

  .link {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-color: inherit;
    transition: text-decoration-color 0.3s ease;
    &:hover {
      text-decoration-color: #fab045;
    }
  }
}

@media screen and (max-width: 636px) {
  .popup {
    padding-left: 8px;
    padding-right: 8px;

    .content {
      max-width: 359px;
    }

    .wrapper {
      gap: 28px;
      padding: 28px 16px 24px;
    }

    .text {
      font-size: 18px;
      line-height: 26.21px;
      max-width: 296px;
    }

    .form {
      grid-template-columns: 1fr;
      gap: 24px;
    }

    .input {
      width: 100%;
      order: 1;
    }

    .button {
      width: 100%;
      order: 3;
    }

    .labelCheckbox {
      grid-column: 1 / span 1;
      order: 2;
    }
  }
}
