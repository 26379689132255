.unity-container {
  position: relative;
  max-height: 100%;
}

.unity-container.unity-mobile {
  position: fixed;
  width: 100%;
  height: 100%;
}

.canvas {
  width: 100%;
  max-width: 100%;
  /* max-height: calc(100% - 38px); */
  border-radius: 14px;
  aspect-ratio: 8 / 5;
  background: url('./assets/Fon.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.unity-mobile #unity-canvas {
  width: 100%;
  height: 100%;
}

.unity-loading-bar {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  display: none;
}

.unity-logo {
  width: 537px;
  height: 397px;
  background: url('./assets/Logo 1.png') no-repeat center;
  background-size: contain;
}

.running-gif {
  width: 537px;
  height: 84px;
  background: url('./assets/run.gif') no-repeat center;
  background-size: contain;
}

.unity-progress-bar-empty {
  width: 141px;
  height: 18px;
  margin: 10px auto 0;
  background: url('./assets/progress-bar-empty-light.png') no-repeat center;
}

.unity-progress-bar-full {
  width: 0%;
  height: 18px;
  background: url('./assets/progress-bar-full-light.png') no-repeat center;
}

.unity-footer {
  position: relative;
}

.unity-mobile #unity-footer {
  display: none;
}

.unity-webgl-logo {
  float: left;
  width: 204px;
  height: 38px;
  background: url('./assets/webgl-logo.png') no-repeat center;
}

.unity-build-title {
  float: right;
  margin-right: 10px;
  line-height: 38px;
  font-family: arial;
  font-size: 18px;
}

.unity-fullscreen-button {
  cursor: pointer;
  height: 52px;
  margin-left: auto;
  background-color: #8fa7bf;
  padding: 10px;
  max-width: 180px;
  font-size: 13px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 22px;
  color: #fff;
  border-radius: 8px;
}

.unity-warning {
  position: absolute;
  left: 50%;
  top: 5%;
  transform: translate(-50%);
  background: white;
  padding: 10px;
  display: none;
}

.wraper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 19px;
  margin-bottom: 42px;
}

.list {
  display: flex;
  column-gap: 16px;
  align-items: center;
}

@media screen and (max-width: 1279px) {
  .wraper {
    margin-top: 23px;
    margin-bottom: 36px;
  }

  /* .canvas {
    aspect-ratio: 4 / 3;
  } */

  .list {
    column-gap: 10px;
  }
}

@media screen and (max-width: 1024px) {
  .unity-fullscreen-button.iosBtnStyles {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .wraper {
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: space-between;
    row-gap: 20px;
    margin-top: 11px;
    margin-bottom: 32px;
  }

  .unity-fullscreen-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
  }

  /* .canvas {
    aspect-ratio: 16 / 9;
  } */

  .unity-logo {
    width: 315.88px;
    height: 233.52px;
  }

  .running-gif {
    width: 315.88px;
    height: 84px;
  }

  .list {
    column-gap: 8px;
  }
}

@media screen and (max-width: 690px) {
  .unity-logo {
    width: 268.5px;
    height: 198.5px;
  }

  .running-gif {
    width: 268.5px;
    height: 64px;
  }
}

@media screen and (max-width: 550px) {
  .list {
    width: 100%;
    justify-content: space-between;
    column-gap: 6px;
  }

  .unity-logo {
    width: 238.8px;
    height: 158.8px;
  }

  .running-gif {
    width: 238.8px;
    height: 64px;
  }

  .unity-progress-bar-empty {
    margin-top: 5px;
  }
}

@media screen and (max-width: 499px) {
  .unity-logo {
    width: 199px;
    height: 132.33px;
  }

  /* .canvas {
    aspect-ratio: 16 / 10;
  } */

  .running-gif {
    width: 199px;
    height: 56px;
  }
}

@media screen and (max-width: 427px) {
  .wraper {
    row-gap: 12px;
  }

  .unity-fullscreen-button {
    height: 46px;
  }

  .list {
    column-gap: 4px;
  }

  .unity-logo {
    width: 170.57px;
    height: 113.42px;
  }

  .running-gif {
    width: 170.57px;
  }
}
